<template>
    <div class="warp">
        <div>
            <section class="doorcard">
                <div class="img-bg"></div>
                <div class="content white">
                    <h3>手机就是车钥匙<br/>解锁智能出行</h3>
                </div>
            </section>
            <section style="padding-top: 4vw;">
                <div class="content-part">
                    <div class="text">
                        <p class="title-part">无需实体车钥匙、无需解锁手机、无需打开应用</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">在钱包App创建数字车钥匙后，钱包内会生成对应车钥匙卡片，使用时无需实体车钥匙、无需解锁手机、无需打开应用。</span>
                        </div>
                    </div>
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard_v2/no-need-to-open-the-app.png" alt="">
                </div>
            </section>
            <section>
                <div class="content-part" style="padding: 0 10vw;">
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard_v2/nfc-carkey.png" alt="">
                    <div class="text">
                        <p class="title-part">手机NFC车钥匙 碰一碰就开门</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">使用手机NFC车钥匙，只需将手机熄屏靠近车辆刷卡感应区域，即可快速解闭锁车门、启动车辆。</span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="content-part">
                    <div class="text">
                        <p class="title-part">手机蓝牙车钥匙 无感解闭锁</p>
                        <div>
                            <span class="circle"></span>
                            <span class="intro">使用手机蓝牙车钥匙，只需靠近车门，无感解锁，离车自动落锁。</span>
                        </div>
                    </div>
                    <img class="img-part" src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/doorcard_v2/bluetooth-car-key.png" alt="">
                </div>
            </section>
            <section>
                <div class="support-device grey">
                    <p class="title">支持设备</p>
                    <Loading v-if="!deviceList.length" height="20"/>
                    <div class="phone-list">
                        <ul>
                            <li v-for="(device, index) in deviceList" :key="index">
                                <img :src="device.deviceUrl" alt="">
                                <p>{{device.deviceName}}</p>
                            </li>
                        </ul>
                        <p class="more">
                            <span @click="checkMore('Devices', '[门卡·车钥匙页面]查看更多机型及刷卡位置')">查看更多机型及刷卡位置 <img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/buscard/check-more.svg" alt=""></span>
                        </p>
                    </div>
                </div>
            </section>
            <section>
                <ul class="explain">
                    <li v-for="(item, index) in carExplainList" :key="index">{{item}}</li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { quickCustomPropToTsmWebsite } from '@/common/sensorsSdk'
    import Loading from "@/components/Loading.vue";
    export default {
        name: 'DoorCard',
        data () {
            return {
                videoModal: false,
                deviceList: [],
                carExplainList: []
            }
        },
        components: {
            Loading
        },
        async mounted () {
            quickCustomPropToTsmWebsite({websiteName: '门卡·车钥匙'})
            const deviceList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/devices.json?num=${Math.random()}`)
            const carExplainList = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/carExplainList.json?num=${Math.random()}`)
            this.deviceList = deviceList.data.slice(0, 8)
            this.carExplainList = carExplainList.data
        },
        methods: {
            checkMore(routeName, text) {
                quickCustomPropToTsmWebsite({websiteClick: text})
                this.$router.push({name: routeName})
            }
        }
    }
</script>

<style scoped>
.warp section .img-bg{
    width: 100%;
    height: 41vw;
}
.warp section .content{
    z-index: 9;
    position: absolute;
    top: 16vw;
    left: 15vw;
    width: 30vw;
}
.warp section .content h3{
    font-size: 3.2vw;
    font-weight: 500;
}
.warp section .content p.title{
    font-size: 2vw;
}
.warp section .content span.desc{
    width: 80%;
    display: block;
    font-size: .9vw;
    margin-top: .5vw;
}
.doorcard{
    overflow-x: inherit;
}
.warp section.doorcard .img-bg{
    background: url("https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/home_v2/car-key.jpg") no-repeat;
    background-size: cover;
}
.content-part{
    display: flex;
    justify-content: space-around;
    padding: 6vw 10vw;
    align-items: center;
}
.content-part .text .title-part{
    font-size: 1.9vw;
    font-weight: 500;
    margin-bottom: .7vw;
}
.text .circle{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    border: 3.5px solid #ddd;
    margin-right: .5vw;
}
.text .intro{
    font-size: .9vw;
    line-height: 2vw;
}
.content-part .img-part{
    width: 24vw;
}
/* 支持设备 */
.support-device{
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: .2px;
}
.support-device .title{
    font-size: 1.9vw;
    font-weight: 500;
    margin-top: 5vw;
}
.support-device .phone-list{
    width: 60%;
    padding-bottom: 7vw;
}
.support-device .phone-list ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.support-device .phone-list ul li{
    text-align: center;
    margin-top: 2.5vw;
    width: 25%;
}
.support-device .phone-list ul li img{
    height: 10vw;
}
.support-device .phone-list ul li p{
    font-size: 1.2vw;
    line-height: 4vw;
}
.support-device .phone-list .more{
    font-size: .8vw;
    letter-spacing: .4px;
    position: absolute;
    bottom: 3vw;
    left: 51%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.support-device .phone-list .more img{
    width: 1.1vw;
    position: relative;
    top: .2vw;
}
</style>